const minWidth = width => `(min-width: ${width}px)`;
const maxWidth = width => `(max-width: ${width}px)`;

class BreakPoint {
  constructor(query) {
    this.query = query;
  }

  toJS() {
    return matchMedia(this.query);
  }

  toCSS() {
    return `@media ${this.query}`;
  }
}

const small = {
  up: new BreakPoint(minWidth(576)),
  down: new BreakPoint(maxWidth(576 - 1))
};

const medium = {
  up: new BreakPoint(minWidth(768)),
  down: new BreakPoint(maxWidth(768 - 1))
};

const large = {
  up: new BreakPoint(minWidth(992)),
  down: new BreakPoint(maxWidth(992 - 1))
};

const xlarge = {
  up: new BreakPoint(minWidth(1296)),
  down: new BreakPoint(maxWidth(1296 - 1))
};

export default {
  small,
  medium,
  large,
  xlarge,
};
