import firebase from "firebase";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyA9n97hbLw7C0PvV3-rNmaOw_2yf-vWiE0",
  authDomain: "danielmaul-v1.firebaseapp.com",
  projectId: "danielmaul-v1"
});

const firestore = firebase.firestore();
firestore.settings({ timestampsInSnapshots: true });

export { firebase, firestore };
export default firebase;
