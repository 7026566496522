function getImageLightness(img) {
  return new Promise(resolve => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0);

    const { data } = ctx.getImageData(0, 0, canvas.width, canvas.height);

    let avg;
    let sum = 0;
    for (let x = 0; x < data.length; x += 4) {
      const r = data[x];
      const g = data[x + 1];
      const b = data[x + 2];

      avg = Math.floor((r + g + b) / 3);
      sum += avg;
    }

    resolve({
      cover: canvas.toDataURL("image/jpeg"),
      lightness: Math.floor(sum / (img.width * img.height))
    });
  });
}

export default getImageLightness;
