import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";

import SVG from "../SVG";

function Arrow(props) {
  const { ...other } = props;

  return (
    <SVG {...other} viewBox="0 0 32 32">
      <path d="M32 16.009c0-.267-.11-.522-.293-.714l-9.899-9.999a.993.993 0 0 0-1.414 0 1.016 1.016 0 0 0 0 1.428l8.193 8.275H1c-.552 0-1 .452-1 1.01s.448 1.01 1 1.01h27.586l-8.192 8.275a1.017 1.017 0 0 0 0 1.428.994.994 0 0 0 1.414 0l9.899-9.999c.187-.189.29-.449.293-.714z" />
    </SVG>
  );
}

const styles = {
  base: {
    //
  },

  svg: {}
};

export default injectSheet(styles)(observer(Arrow));
