import React from "react";
import injectSheet from "react-jss";
import { observer, inject } from "mobx-react";
import propTypes from "~/constants/propTypes";
import bp from "~/constants/breakpoints";

class Video extends React.Component {
  state = {
    width: 0,
    height: 0,
    aspectRatio: 0,
    mobile: false
  };

  handleResize = () => {
    const { offsetWidth: width, offsetHeight: height } = this.base;
    this.setState({ width, height, mobile: bp.medium.down.toJS().matches });
  };

  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleMetadataLoad = event => {
    this.setState({
      aspectRatio: event.target.videoHeight / event.target.videoWidth
    });
  };

  getVideoStyle() {
    const { width, height, aspectRatio } = this.state;
    if (width * aspectRatio > height) return { width: "100%" };
    else return { height: "100%" };
  }

  render() {
    const {
      src,
      width,
      height,
      projectStore,
      classes
    } = this.props;

    const baseStyle = {
      flex: `0 0 ${this.state.mobile ? 100 : width}%`,
      paddingTop: `${this.state.mobile ? height : (height / 100) * width}%`
    };

    return (
      <div
        style={baseStyle}
        className={classes.base}
        ref={el => (this.base = el)}
      >
        <video
          loop
          muted
          autoPlay
          style={this.getVideoStyle()}
          className={classes.video}
          src={projectStore.resolveImage(src)}
          onLoadedMetadata={this.handleMetadataLoad}
        />
      </div>
    );
  }
}

Video.propTypes = {
  src: propTypes.string.isRequired,
  width: propTypes.number,
  height: propTypes.number,
  size: propTypes.string
  // color: propTypes.string,
  // position: propTypes.string
};

Video.defaultProps = {
  width: 100,
  height: 100,
  size: "cover"
  // color: "transparent"
};

const styles = {
  base: {
    position: "relative",
    overflow: "hidden"
  },

  video: {
    top: "50%",
    left: "50%",
    // width: "100%",
    // height: "100%",
    position: "absolute",
    transform: "translateX(-50%) translateY(-50%)"
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // animation: "fadeIn 200ms ease"
  }
};

export default injectSheet(styles)(inject("projectStore")(observer(Video)));
