import React from "react";

class Breakpoint extends React.Component {
  media = window.matchMedia(this.props.query);

  state = {
    matches: this.media.matches
  };

  componentWillMount() {
    this.media.addListener(this.handleChange);
  }

  componentWillUnmount() {
    this.media.removeListener(this.handleChange);
  }

  handleChange = event => {
    this.setState({ matches: event.matches });
  };

  render() {
    const { children } = this.props;
    const { matches } = this.state;
    if (typeof children === "function") return children(matches);
    if (matches) return this.props.children;
    return null;
  }
}

export default Breakpoint;
