import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";

import Text from "./Text";
import Image from "./Image";
import Video from "./Video";

function Block(props) {
  const { type, ...other } = props;

  switch (type) {
    case "text":
      return <Text {...other} />;
    case "image":
      return <Image {...other} />;
    case "video":
      return <Video {...other} />;
  }
}

Block.propTypes = {
  //
};

Block.defaultProps = {
  //
};

const styles = {
  //
};

export default injectSheet(styles)(observer(Block));
