import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";
import propTypes from "~/constants/propTypes";
import marked from "marked";
import bp from "~/constants/breakpoints";
import Breakpoint from "../../../../components/Breakpoint";

class Text extends React.Component {
  render() {
    const { width, text, classes } = this.props;

    return (
      <Breakpoint query={bp.medium.down.query}>
        {matches => {
          const baseStyle = {
            flex: `0 0 ${matches ? 100 : width}%`,
          };

          return (
            <div
              style={baseStyle}
              className={classes.base}
              dangerouslySetInnerHTML={{
                __html: marked(text.replace(/\\n/g, "\n"))
              }}
            />
          );
        }}
      </Breakpoint>
    );
  }
}

Text.propTypes = {
  width: propTypes.number,
};

Text.defaultProps = {
  width: 100,
};

const styles = {
  base: {
    padding: 32,
    fontSize: 16,
    boxSizing: "border-box",
    overflow: "hidden",

    [bp.medium.up.toCSS()]: {
      padding: ["4%", "18%"],
      fontSize: 24
    },

    "& *:first-child": {
      marginTop: 0
    },

    "& *:last-child": {
      marginBottom: 0
    },

    "& h1": {
      fontSize: "1.333em"
    },

    "& a": {
      color: "#333333"
    }
  }
};

export default injectSheet(styles)(observer(Text));
