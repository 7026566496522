import React from "react";
import injectSheet from "react-jss";
import { inject, observer } from "mobx-react";

import bp from "~/constants/breakpoints";
import Header from "~/components/Header";
import NotFound from "~/scenes/NotFound";

import Project from "./component/Project";

class Home extends React.Component {
  componentWillMount() {
    this.props.projectsStore.fetchProjects();
  }

  render() {
    const { projectsStore, classes } = this.props;

    if (projectsStore.state === "ready") return null;
    if (projectsStore.state === "error") return <NotFound />;
    if (projectsStore.state === "pending") return null;

    return (
      <div className={classes.base}>
        <Header />

        <div className={classes.container}>
          <div className={classes.intro}>
            My name is <b>Daniel Maul</b> and I am an interdisciplinary graphic
            designer. I specialize in telling stories through design and build
            brand experiences that people remember.
          </div>
          <div className={classes.projects}>
            {projectsStore.data.map(project => (
              <Project key={project.id} {...project} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  base: {},

  container: {
    maxWidth: 1920,
    padding: [0, "5vw", "5vw"],
    margin: [0, "auto"],
    boxSizing: "content-box"
  },

  intro: {
    fontSize: 20,
    padding: [32, 0, 64, 0],
    maxWidth: "100%"
  },

  [bp.small.up.toCSS()]: {
    intro: {
      maxWidth: "80%"
    }
  },

  [bp.medium.up.toCSS()]: {
    intro: {
      fontSize: 28,
      padding: [72, 0, 144, 0]
    }
  },

  [bp.large.up.toCSS()]: {
    intro: {
      maxWidth: "60%",
      marginLeft: "10%",
      fontSize: 32
    }
  },

  projects: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "row wrap",
    margin: -16
  }
};

export default injectSheet(styles)(inject("projectsStore")(observer(Home)));
