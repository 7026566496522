import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";
import { DateTime } from "luxon";

import bp from "~/constants/breakpoints";
import Header from "~/components/Header";
import about from "~/assets/about.jpg";

function About(props) {
  const { classes } = props;

  return (
    <div className={classes.base}>
      <Header />
      <div className={classes.container}>
        <img src={about} className={classes.image} />

        <div className={classes.inner}>
          <p>
            My name is Daniel Maul and I am an interdisciplinary Digital
            designer experienced in Concept development, Graphic design and
            UI/UX. I aim to create clean and intuitive work that is contemporary
            and cutting edge.
          </p>
          <p>
            Originally from a small town near nothing in Denmark and former
            blogger and designer at Designerminds. I am currently crafting
            digital experiences at Companyoung in Aalborg, Denmark.
          </p>

          <div className={classes.info}>
            <div>
              <div>Home</div>
              <div>Aalborg, Denmark</div>
            </div>
            <div>
              <div>Age</div>
              <div>
                {Math.floor(
                  DateTime.local()
                    .diff(DateTime.fromISO("1989-10-02"))
                    .as("years")
                )}
              </div>
            </div>
            <div>
              <div>Work experience</div>
              <div>
                {Math.floor(
                  DateTime.local()
                    .diff(DateTime.fromISO("2008-01-01"))
                    .as("years")
                )}{" "}
                years
              </div>
            </div>
            <div>
              <div>LinkedIn</div>
              <div>
                <a href="https://www.linkedin.com/in/daniel-maul-5104b939/">
                  daniel-maul-5104b939
                </a>
              </div>
            </div>
          </div>

          <p>
            I have had the privilege of working with companies such as LEGO,
            IKEA, TOP-TOY, Club La Santa, TDC, NAC, Visit Denmark, Beierholm,
            Defence Command Denmark, Salling Group, Apotekerforeningen,
            Plastindustrien, Bilbranchen, KPMG, Scania, Blue Water Shipping,
            Oure.
          </p>

          <div className={classes.facts}>
            <div>
              <div>Specialties &mdash;</div>
              <div>&rarr; Concept development</div>
              <div>&rarr; Identity</div>
              <div>&rarr; User experiences</div>
              <div>&rarr; Digital design</div>
            </div>
            <div>
              <div>Contact &mdash;</div>
              <div>Daniel Maul</div>
              <div>dm@danielmaul.dk</div>
              <div>+45 60245047</div>
              <div>Aalborg, Denmark</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  base: {
    "& p": {
      marginTop: 0,
      marginBottom: "2em"
    }
  },

  container: {
    maxWidth: 1920,
    padding: [0, "5vw", "5vw"],
    margin: [0, "auto"],
    boxSizing: "content-box"
  },

  image: {
    width: "100%",
    height: 280,
    objectFit: "cover",
    objectPosition: "56%",

    [bp.large.up.toCSS()]: {
      "&": {
        height: "auto",
        objectPosition: "50% 50%"
      }
    }
  },

  inner: {
    fontSize: 20,
    padding: "10vw",
    background: "#ffffff",

    [bp.large.up.toCSS()]: {
      "&": {
        padding: "10vw 20vw"
      }
    },

    [bp.xlarge.up.toCSS()]: {
      "&": {
        fontSize: 32
      }
    }
  },

  info: {
    fontSize: 14,
    color: "#95989A",

    [bp.large.up.toCSS()]: {
      "&": {
        display: "flex",
        justifyContent: "space-between"
      }
    },

    [bp.xlarge.up.toCSS()]: {
      "&": {
        fontSize: 20
      }
    },

    "& > div": {
      marginBottom: "1em",

      "&:last-of-type": {
        marginBottom: "2.857em"
      },

      "& > div:first-of-type": {
        fontWeight: 700,
        fontFamily: "Space Mono, monospace"
      },

      "& a": {
        color: "#95989A",
        textDecoration: "none"
      }
    }
  },

  facts: {
    display: "flex",
    flexFlow: "row wrap",

    "& > div": {
      flexBasis: "100%",
      marginBottom: "2em",
      fontSize: 24,

      [bp.large.up.toCSS()]: {
        "&": {
          flexBasis: "50%"
        }
      },

      "&:last-of-type": {
        marginBottom: 0
      },

      "& > div:first-of-type": {
        fontWeight: 700
      }
    }
  }
};

export default injectSheet(styles)(observer(About));
