import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import bp from "~/constants/breakpoints";
import Arrow from "~/components/Arrow";

function Footer(props) {
  const { slug, title, role, classes } = props;

  return (
    <div className={classes.base}>
      <div className={classes.container}>
        <div>
          <div className={classes.label}>See next project</div>
          <div className={classes.title}>{title}</div>
          <div className={classes.role}>{role}</div>
        </div>
        <Link to={"/" + slug} className={classes.next}>
          <Arrow className={classes.arrow} />

        </Link>
      </div>
    </div>
  );
}

const styles = {
  base: {
    backgroundColor: "#333333",
    color: "#ffffff"
  },

  container: {
    maxWidth: 1920,
    padding: [48, "5vw"],
    boxSizing: "content-box",
    margin: [0, "auto"],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  label: {
    fontSize: 16,
    fontWeight: 700,
    color: "#95989A"
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.2,

    [bp.medium.up.toCSS()]: {
      fontSize: 48
    }
  },

  arrow: {
    "& svg" : {
      width: 32,

      [bp.medium.up.toCSS()]: {
        width: 64,
      }
    }
  },

  role: {
    fontSize: 16,
    fontWeight: 300
  },

  next: {
    color: "#ffffff"
  }
};

export default injectSheet(styles)(observer(Footer));
