import React from "react";
import injectSheet from "react-jss";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import getImageLightness from "./getImageLightness";
import bp from "~/constants/breakpoints";

class Project extends React.Component {
  state = {
    cover: null,
    lightness: 50
  };

  componentWillMount() {
    const {
      slug,
      info: { cover },
      projectsStore
    } = this.props;

    const img = new Image();
    const src = projectsStore.resolveImage(slug, cover);

    img.crossOrigin = "Anonymous";

    img.onload = () => {
      getImageLightness(img).then(({ cover, lightness }) => {
        this.setState({ cover, lightness });
      });
    };

    img.src = src;
  }

  renderImage = () => {
    const {
      slug,
      info: { title, role },
      classes
    } = this.props;

    const { cover, lightness } = this.state;

    const style = {
      backgroundImage: `url(${cover})`,
      color: lightness > 127 ? "#96989A" : "#ffffff"
    };

    if (!cover) return null;

    return (
      <Link to={"/" + slug} style={style} className={classes.image}>
        <div className={classes.info}>
          <div className={classes.title}>{title}</div>
          <div className={classes.role}>{role}</div>
        </div>
      </Link>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.base}>
        <div className={classes.inner}>
          <div className={classes.content}>{this.renderImage()}</div>
        </div>
      </div>
    );
  }
}

const styles = {
  base: {
    width: "100%",
    paddingTop: "80%",
    position: "relative"
  },

  [bp.large.up.toCSS()]: {
    base: {
      width: "50%",
      paddingTop: "40%"
    }
  },

  inner: {
    top: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    boxSizing: "border-box",
    padding: 16
  },

  content: {
    width: "100%",
    height: "100%",
    background: "#ffffff"
  },

  image: {
    width: "100%",
    height: "100%",
    display: "flex",
    background: "center / cover",
    alignItems: "flex-end",
    transition: "200ms ease",
    textDecoration: "none",
    fontWeight: 700,
    animation: "fadeIn 200ms ease",

    "&:hover": {
      boxShadow: "0px 15px 30px 0px rgba(0,0,0,0.1)"
    }
  },

  image_loaded: {
    background: "#000000"
  },

  info: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    whiteSpace: "nowrap",
    padding: "3%"
  },

  role: {
    fontWeight: 400
  },

  [bp.large.down.toCSS()]: {
    role: {
      display: "none"
    }
  },

  "@keyframes fadeIn": {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }
};

export default injectSheet(styles)(inject("projectsStore")(observer(Project)));
