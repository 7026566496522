import React from "react";
import { observer } from "mobx-react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./scenes/Home";
import About from "./scenes/About";
import Project from "./scenes/Project";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/:slug" component={Project} />
      </Switch>
    </BrowserRouter>
  );
}

export default observer(App);
