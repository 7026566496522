import React from "react";
import injectSheet from "react-jss";
import { observer, inject } from "mobx-react";

import Header from "~/components/Header";
import NotFound from "~/scenes/NotFound";

import Info from "./components/Info";
import Block from "./components/Block";
import Footer from "./components/Footer";

class Project extends React.Component {
  componentWillMount() {
    this.fetchProject(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.fetchProject(nextProps);
  }

  fetchProject(props) {
    const { match, projectStore } = props;

    projectStore.fetchProject(match.params.slug);
  }

  render() {
    const { projectStore, classes } = this.props;

    if (projectStore.state === "ready") return null;
    if (projectStore.state === "error") return <NotFound />;
    if (projectStore.state === "pending") return null;

    const { data } = projectStore;

    return (
      <div className={classes.base}>
        <Header />
        <div className={classes.container}>
          <div className={classes.blocks}>
            <Info {...data.info} />
            {data.blocks.map((block, i) => <Block key={i} {...block} />)}
          </div>
        </div>
        <Footer {...data.next} />
      </div>
    );
  }
}

Project.propTypes = {
  //
};

Project.defaultProps = {
  //
};

const styles = {
  base: {},

  container: {
    maxWidth: 1920,
    padding: [0, "5vw", "5vw"],
    margin: [0, "auto"],
    boxSizing: "content-box"
  },

  blocks: {
    display: "flex",
    flexFlow: "row wrap"
  }
};

export default injectSheet(styles)(inject("projectStore")(observer(Project)));
