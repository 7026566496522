import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

// import propTypes from '../constants/propTypes';
import Logo from "~/assets/logo.svg";

function Header(props) {
  const { classes } = props;

  return (
    <div className={classes.base}>
      <div className={classes.logo}>
        <Link to="/">
          <Logo height={32} />
        </Link>
      </div>
      <div className={classes.navigation}>
        {/*<Link to="/">home</Link>*/}
        <Link to="/about">about</Link>
      </div>
    </div>
  );
}

const styles = {
  base: {
    height: 90,
    maxWidth: 1920,
    padding: [0, "5vw"],
    margin: [0, "auto"],
    boxSizing: "content-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& a": {
      color: "#96989A",
      textDecoration: "none",
      fontWeight: 300
    }
  },

  logo: {
    "& a": {
      color: "#d8d9da"
    }
  },

  navigation: {
    "& a:not(:first-of-type)": {
      marginLeft: 30
    }
  }
};

export default injectSheet(styles)(observer(Header));
