import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";

import bp from "~/constants/breakpoints";

function Info(props) {
  const {
    title,
    body,
    client,
    agency,
    role,
    website,
    year,
    color,
    backgroundColor,
    classes
  } = props;

  const style = {
    color,
    backgroundColor
  };

  const SpecificsItem = ({ label, value }) => {
    if (!value) return null;

    return (
      <div className={classes.specifics_item}>
        <div className={classes.specifics_label}>{label}</div>
        <div>{value}</div>
      </div>
    );
  };

  return (
    <div style={style} className={classes.base}>
      <div className={classes.title}>{title}</div>
      <div className={classes.body}>{body}</div>
      <div className={classes.specifics}>
        <SpecificsItem label="Client" value={client} />
        <SpecificsItem label="Agency" value={agency} />
        <SpecificsItem label="Role" value={role} />
        <SpecificsItem label="Website" value={website} />
        <SpecificsItem label="Year" value={year} />
      </div>
    </div>
  );
}

const styles = {
  base: {
    width: "100%",
    padding: 32,

    [bp.large.up.toCSS()]: {
      padding: ["4vw", "8vw"]
    }
  },

  title: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 24,

    [bp.large.up.toCSS()]: {
      fontSize: 16,
      marginBottom: 16
    }
  },

  body: {
    fontSize: 20,
    fontWeight: 300,
    marginBottom: 24,

    [bp.medium.up.toCSS()]: {
      fontSize: 26,
      width: "70%",
      marginBottom: 64
    },

    [bp.large.up.toCSS()]: {
      width: "50%",
      fontSize: 32,
      flex: "1 1",
      marginBottom: 128
    }
  },

  specifics: {
    [bp.medium.up.toCSS()]: {
      display: "flex",
      flexWrap: "wrap"
    }
  },
  specifics_item: {
    marginBottom: 16,

    [bp.medium.up.toCSS()]: {
      flex: "0 1 33.33333%"
    },

    [bp.large.up.toCSS()]: {
      marginBottom: 0,
      marginRight: "4vw",
      flex: "0 0 auto"
    },

    "&:last-of-type": {
      marginBottom: 0,

      [bp.large.up.toCSS()]: {
        marginRight: 0
      }
    }
  },
  specifics_label: {
    fontWeight: 700
  }
};

export default injectSheet(styles)(observer(Info));
