import React from "react";
import injectSheet from "react-jss";
import { observer, inject } from "mobx-react";
import propTypes from "~/constants/propTypes";
import bp from "~/constants/breakpoints";
import getImageLightness from "../../../Home/component/Project/getImageLightness";

class Image extends React.Component {
  state = {
    data: null,
    mobile: bp.small.down.toJS().matches
  };

  handleResize = () => {
    this.setState({ mobile: bp.medium.down.toJS().matches });
  };

  componentWillMount() {
    const { src, projectStore } = this.props;

    window.addEventListener("resize", this.handleResize);

    const img = document.createElement("img");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    img.crossOrigin = "Anonymous";

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);
      this.setState({ data: canvas.toDataURL("image/jpeg") });
    };

    img.src = projectStore.resolveImage(src);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const {
      width,
      height,
      size,
      color,
      position,
      classes
    } = this.props;
    const { data, mobile } = this.state;

    const baseStyle = {
      flex: `0 0 ${mobile ? 100 : width}%`,
      paddingTop: `${mobile ? height : height / 100 * width}%`
    };

    const imageStyle = {
      backgroundSize: size,
      backgroundColor: color,
      backgroundPosition: position,
      backgroundImage: `url(${data})`
    };

    return (
      <div style={baseStyle} className={classes.base}>
        {data ? <div style={imageStyle} className={classes.image} /> : null}
      </div>
    );
  }
}

Image.propTypes = {
  src: propTypes.string.isRequired,
  width: propTypes.number,
  height: propTypes.number,
  size: propTypes.string,
  color: propTypes.string,
  position: propTypes.string
};

Image.defaultProps = {
  width: 100,
  height: 100,
  size: "cover",
  color: "transparent"
};

const styles = {
  base: {
    position: "relative"
  },

  image: {
    top: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    animation: "fadeIn 200ms ease"
  },

  "@keyframes fadeIn": {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }
};

export default injectSheet(styles)(inject("projectStore")(observer(Image)));
