import React from "react";
import { render } from "react-dom";
import { Provider } from "mobx-react";
import "sanitize.css";

import "./index.css";
import RootStore from "./stores";
import App from "./App";

render(
  <Provider {...new RootStore()}>
    <App />
  </Provider>,
  document.getElementById("root")
);
