import { observable, action, runInAction } from "mobx";
import { firestore } from "../constants/firebase";

class ProjectStore {
  @observable data = {};
  @observable state = "ready";

  @action
  async fetchProject(slug) {
    this.state = "pending";

    const projects = await firestore
      .collection("projects")
      .where("slug", "==", slug)
      .limit(1)
      .get();

    if (projects.empty) {
      runInAction(() => {
        this.state = "error";
        this.data = { status: 404, title: "Place not found" };
      });
      return;
    }

    const project = projects.docs[0];

    runInAction(() => {
      this.data = {
        id: project.id,
        ...project.data()
      };
      this.state = "success";
    });
  }

  resolveImage(path) {
    return `https://firebasestorage.googleapis.com/v0/b/danielmaul-v1.appspot.com/o/projects%2F${
      this.data.slug
    }%2F${path}?alt=media`;
  }
}

export default ProjectStore;
