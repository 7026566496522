import { observable, action, runInAction } from "mobx";
import { firestore } from "../constants/firebase";

class ProjectStore {
  @observable data = [];
  @observable state = "ready";

  @action
  async fetchProjects() {
    this.state = "pending";

    const querySnapshot = await firestore
      .collection("projects")
      .orderBy("order")
      .get();

    if (querySnapshot.empty) {
      runInAction(() => {
        this.state = "error";
        this.data = { status: 404, title: "Place not found" };
      });
      return;
    }

    const projects = querySnapshot.docs.map(queryDocumentSnapshot => {
      let project = queryDocumentSnapshot.data();
      project.id = queryDocumentSnapshot.id;
      return project;
    });

    runInAction(() => {
      this.data = projects;
      this.state = "success";
    });
  }

  resolveImage(slug, path) {
    return `https://firebasestorage.googleapis.com/v0/b/danielmaul-v1.appspot.com/o/projects%2F${slug}%2F${path}?alt=media`;
  }
}

export default ProjectStore;
