import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";

function NotFound(props) {
  return <div>NotFound</div>;
}

NotFound.propTypes = {
  //
};

NotFound.defaultProps = {
  //
};

const styles = {
  //
};

export default injectSheet(styles)(observer(NotFound));
