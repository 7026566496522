import React from "react";
import injectSheet from "react-jss";
import { observer } from "mobx-react";
import classNames from "classnames";
import propTypes from "../../constants/propTypes";

function SVG(props) {
  const {
    viewBox,
    color,
    width,
    height,
    inner,
    className,
    children,
    classes,
    ...other
  } = props;

  // fix ie svg scaling by defining a static height and width
  const [, , viewBoxWidth, viewBoxHeight] = viewBox.split(" ");

  const hRatio = viewBoxHeight / viewBoxWidth;
  const vRatio = viewBoxWidth / viewBoxHeight;

  let svgWidth, svgHeight;

  if (width && !height) {
    svgWidth = width;
    svgHeight = width * hRatio;
  }
  if (!width && height) {
    svgWidth = height * vRatio;
    svgHeight = height;
  }

  const renderSVG = () => {
    const svg = (
      <svg
        width={svgWidth}
        height={svgHeight}
        viewBox={viewBox}
        color={color}
        style={{ display: "block" }}
        xmlns="http://www.w3.org/2000/svg"
      >
        {children}
      </svg>
    );

    if (inner) {
      return React.cloneElement(inner, { children: svg });
    }

    return svg;
  };

  return (
    <div {...other} className={classNames(className, classes.base)}>
      {renderSVG()}
    </div>
  );
}

SVG.propTypes = {
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  height: propTypes.oneOfType([propTypes.string, propTypes.number]),
  viewBox: propTypes.string,
  transform: propTypes.string,
  className: propTypes.string,
  children: propTypes.node.isRequired
};

SVG.defaultProps = {
  width: null,
  height: null,
  viewBox: null,
  transform: null,
  className: null
};

const styles = {
  base: {
    display: "inline-block",
    verticalAlign: "middle"
  }
};

export default injectSheet(styles)(observer(SVG));
